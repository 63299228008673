<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar />
        <SideBar />
        <v-main class="main-container">
            <v-container fluid style="width: 85%">
                <v-row align="end">
                    <v-col cols="4">
                        <div
                            class="text-h4 font-weight-light text-left pt-14"
                            style="color: grey"
                        >
                            Favorites
                        </div>
                    </v-col>
                    <v-col cols="4" class="align-center">
                        <v-tabs
                            color="#0091ff"
                            centered
                            optional
                            style="
                                width: 100% !important;
                                background: transparent !important;
                            "
                            v-model="keyMetricsTabIndex"
                            @change="periodTabChange()"
                            ref="selectorTabs"
                            background-color="transparent"
                        >
                            <v-tab
                                style="max-width: 10px !important"
                                :disabled="queryInProgress"
                                >14D</v-tab
                            >
                            <v-divider vertical inset></v-divider>
                            <v-tab
                                style="max-width: 10px !important"
                                :disabled="queryInProgress"
                                >30D</v-tab
                            >
                            <v-divider vertical inset></v-divider>
                            <v-tab
                                style="max-width: 10px !important"
                                :disabled="queryInProgress"
                                >60D</v-tab
                            >
                        </v-tabs>
                    </v-col>
                    <v-col cols="4">
                        <p
                            class="text-end mb-0 mr-4 pt-2 font-weight-light"
                            :style="{
                                color: theme == 'light' ? '#545454' : 'white'
                            }"
                        >
                            {{ periodDates }}
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            class="elevation-1 favoritesDataTable"
                            :custom-sort="favoriteTableSort"
                        >
                            <template v-slot:header.name="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.origin="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.destination="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.score="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.equipmenttype="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.total_margin="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.avg_otd="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.volume="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.avg_cogs="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.avg_revenue="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template v-slot:header.avg_miles="{ header }">
                                <Tooltip
                                    :header_text="header.text"
                                    :header_tool="header.tooltip"
                                />
                            </template>
                            <template
                                v-slot:header.margin_per_load="{ header }"
                            >
                                <v-tooltip top max-width="25%">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <v-btn
                                                icon
                                                @click.stop.prevent="
                                                    marginPerLoadUnit = 'dollar'
                                                "
                                                x-small
                                                :color="
                                                    marginPerLoadUnit ==
                                                    'dollar'
                                                        ? '#0091ff'
                                                        : 'inherit'
                                                "
                                            >
                                                <v-icon
                                                    >mdi-currency-usd</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                icon
                                                @click.stop.prevent="
                                                    marginPerLoadUnit =
                                                        'percent'
                                                "
                                                x-small
                                                :color="
                                                    marginPerLoadUnit ==
                                                    'percent'
                                                        ? '#0091ff'
                                                        : 'inherit'
                                                "
                                            >
                                                <v-icon
                                                    >mdi-percent-outline</v-icon
                                                >
                                            </v-btn>
                                            <br />
                                            {{ header.text }}
                                        </span>
                                    </template>
                                    <span>{{ header.tooltip }}</span>
                                </v-tooltip>
                            </template>
                            <template
                                v-slot:header.avg_margin_dollars="{ header }"
                            >
                                <v-tooltip top max-width="25%">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ header.text }}</span>
                                    </template>
                                    <span>{{ header.tooltip }}</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.favoriteIcon="{ item }">
                                <v-tooltip
                                    top
                                    max-width="25%"
                                    open-delay="1250"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            @click.stop.prevent="
                                                updateFavoriteLanes(item)
                                            "
                                            class="mr-3"
                                            small
                                        >
                                            <v-icon color="orange" small>
                                                mdi-star
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Favorite Lane</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.score="{ item }">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-width="220"
                                    offset-x
                                    offset-y
                                    transition="scroll-x-transition"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-chip
                                            :color="getColor(item.score)"
                                            dark
                                            v-on="on"
                                        >
                                            {{ formatScore(item.score) }}
                                        </v-chip>
                                    </template>
                                    <v-card class="pa-1">
                                        <v-card-title class="pb-0">
                                            Score Breakdown
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col cols="12">
                                                    <v-row class="ma-0 py-2">
                                                        On-Time Pickup
                                                        {{
                                                            formatScore(
                                                                item.avg_otp_score
                                                            ) == '--'
                                                                ? '--'
                                                                : ''
                                                        }}
                                                        <v-progress-linear
                                                            v-if="
                                                                formatScore(
                                                                    item.avg_otp_score
                                                                ) != '--'
                                                            "
                                                            :value="
                                                                formatScore(
                                                                    item.avg_otp_score
                                                                )
                                                            "
                                                            :color="
                                                                getColor(
                                                                    formatScore(
                                                                        item.avg_otp_score
                                                                    )
                                                                )
                                                            "
                                                        ></v-progress-linear>
                                                    </v-row>
                                                    <v-row class="ma-0 py-2">
                                                        On-Time Delivery
                                                        {{
                                                            formatScore(
                                                                item.avg_otd_score
                                                            ) == '--'
                                                                ? '--'
                                                                : ''
                                                        }}
                                                        <v-progress-linear
                                                            v-if="
                                                                formatScore(
                                                                    item.avg_otd_score
                                                                ) != '--'
                                                            "
                                                            :value="
                                                                formatScore(
                                                                    item.avg_otd_score
                                                                )
                                                            "
                                                            :color="
                                                                getColor(
                                                                    formatScore(
                                                                        item.avg_otd_score
                                                                    )
                                                                )
                                                            "
                                                        ></v-progress-linear>
                                                    </v-row>
                                                    <v-row class="ma-0 py-2">
                                                        Appointment Setting
                                                        {{
                                                            formatScore(
                                                                item.avg_ltu_score
                                                            ) == '--'
                                                                ? '--'
                                                                : ''
                                                        }}
                                                        <v-progress-linear
                                                            v-if="
                                                                formatScore(
                                                                    item.avg_ltu_score
                                                                ) != '--'
                                                            "
                                                            :value="
                                                                formatScore(
                                                                    item.avg_ltu_score
                                                                )
                                                            "
                                                            :color="
                                                                getColor(
                                                                    formatScore(
                                                                        item.avg_ltu_score
                                                                    )
                                                                )
                                                            "
                                                        ></v-progress-linear>
                                                    </v-row>
                                                    <v-row class="ma-0 py-2">
                                                        Pre-Book
                                                        {{
                                                            formatScore(
                                                                item.avg_prebook_score
                                                            ) == '--'
                                                                ? '--'
                                                                : ''
                                                        }}
                                                        <v-progress-linear
                                                            v-if="
                                                                formatScore(
                                                                    item.avg_prebook_score
                                                                ) != '--'
                                                            "
                                                            :value="
                                                                formatScore(
                                                                    item.avg_prebook_score
                                                                )
                                                            "
                                                            :color="
                                                                getColor(
                                                                    formatScore(
                                                                        item.avg_prebook_score
                                                                    )
                                                                )
                                                            "
                                                        ></v-progress-linear>
                                                    </v-row>
                                                    <v-row class="ma-0 py-2">
                                                        Cost Performance
                                                        <v-progress-linear
                                                            :value="
                                                                formatScore(
                                                                    item.avg_cost_score
                                                                )
                                                            "
                                                            :color="
                                                                getColor(
                                                                    formatScore(
                                                                        item.avg_cost_score
                                                                    )
                                                                )
                                                            "
                                                        ></v-progress-linear>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="6"
                                                    class="justify-center"
                                                >
                                                    <v-col>
                                                        <v-row
                                                            class="justify-center scorecardMetricValue"
                                                        >
                                                            {{
                                                                formatHoursToBusinessDays(
                                                                    item.avg_clt,
                                                                    1
                                                                )
                                                            }}
                                                        </v-row>
                                                        <v-row
                                                            class="justify-center font-weight-light"
                                                        >
                                                            {{ 'Avg. CLT' }}
                                                        </v-row>
                                                    </v-col>
                                                </v-col>
                                                <v-col
                                                    :cols="6"
                                                    class="justify-center"
                                                >
                                                    <v-col>
                                                        <v-row
                                                            class="justify-center scorecardMetricValue"
                                                        >
                                                            {{
                                                                formatHoursToBusinessDays(
                                                                    item.avg_blt,
                                                                    1
                                                                )
                                                            }}
                                                        </v-row>
                                                        <v-row
                                                            class="justify-center font-weight-light"
                                                        >
                                                            {{ 'Avg. BLT' }}
                                                        </v-row>
                                                    </v-col>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    class="justify-center"
                                                >
                                                    <v-col>
                                                        <v-row
                                                            class="justify-center scorecardMetricValue"
                                                        >
                                                            {{
                                                                formatHoursToBusinessDays(
                                                                    item.avg_prebook,
                                                                    1
                                                                )
                                                            }}
                                                        </v-row>
                                                        <v-row
                                                            class="justify-center font-weight-light"
                                                        >
                                                            {{ 'Avg. Prebook' }}
                                                        </v-row>
                                                    </v-col>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    cols="6"
                                                    class="justify-center"
                                                >
                                                    <v-col>
                                                        <v-row
                                                            class="justify-center scorecardMetricValue"
                                                        >
                                                            {{
                                                                formatPercent(
                                                                    item.avg_otp
                                                                )
                                                            }}
                                                        </v-row>
                                                        <v-row
                                                            class="justify-center font-weight-light"
                                                        >
                                                            {{ 'OTP' }}
                                                        </v-row>
                                                    </v-col>
                                                </v-col>
                                                <v-col
                                                    cols="6"
                                                    class="justify-center"
                                                >
                                                    <v-col>
                                                        <v-row
                                                            class="justify-center scorecardMetricValue"
                                                        >
                                                            {{
                                                                formatPercent(
                                                                    item.avg_otd
                                                                )
                                                            }}
                                                        </v-row>
                                                        <v-row
                                                            class="justify-center font-weight-light"
                                                        >
                                                            {{ 'OTD' }}
                                                        </v-row>
                                                    </v-col>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                            <template v-slot:item.equipmenttype="{ item }">
                                {{
                                    item.shipmentmode &&
                                    item.shipmentmode == 'IMDL'
                                        ? 'IMDL - ' + item.equipmenttype
                                        : item.equipmenttype
                                }}
                            </template>
                            <template v-slot:item.total_margin="{ item }">
                                {{ formatDollars(item.total_margin) }}
                            </template>
                            <template v-slot:item.avg_margin="{ item }">
                                {{ formatPercent(item.avg_margin, 1) }}
                            </template>
                            <template v-slot:item.avg_margin_dollars="{ item }">
                                {{ formatDollars(item.avg_margin_dollars) }}
                            </template>
                            <template v-slot:item.margin_per_load="{ item }">
                                {{
                                    marginPerLoadUnit == 'percent'
                                        ? formatPercent(item.avg_margin, 1)
                                        : formatDollars(item.avg_margin_dollars)
                                }}
                            </template>
                            <template v-slot:item.avg_otd="{ item }">
                                {{ formatPercent(item.avg_otd) }}
                            </template>
                            <template v-slot:item.avg_cogs="{ item }">
                                {{ formatDollars(item.avg_cogs) }}
                            </template>
                            <template v-slot:item.avg_revenue="{ item }">
                                {{ formatDollars(item.avg_revenue) }}
                            </template>
                            <template v-slot:item.avg_miles="{ item }">
                                {{ formatDecimal(item.avg_miles, 0) }}
                            </template>
                            <template v-slot:item.volume="{ item }">
                                {{ formatNumber(item.volume) }}
                            </template>
                            <template v-slot:item.gotoDrilldownIcon="{ item }">
                                <v-tooltip
                                    top
                                    max-width="25%"
                                    open-delay="1250"
                                >
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <v-icon
                                                style="
                                                    font-size: 20px !important;
                                                "
                                                @click.stop.prevent="
                                                    gotoItemLaneDrilldown(item)
                                                "
                                                class="gotoIcon"
                                            >
                                                mdi-forwardburger
                                            </v-icon>
                                        </span>
                                    </template>
                                    <span>Lane Drilldown</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog v-model="laneDrilldown" width="80%" overlay-color="black">
                <LaneDrilldown
                    :drilldown_object="drilldownObject"
                    :prop_broker="isBrokerUser"
                    @stopLoading="isLoading = false"
                />
            </v-dialog>
        </v-main>
        <SplashScreen style="z-index: 220" :is_loading="isLoading" />
    </v-app>
</template>
<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import * as stateAPI from '../stateAPI';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as fetchAccountDetails from '../fetchAccountDetails';
import _ from 'lodash';
import * as fetchShipments from '../fetchShipments';
import * as format from '../formatShipmentData';
import * as utils from '../utils';
import LaneDrilldown from '../components/LaneDrilldown';
import SplashScreen from '../components/SplashScreen';
import Tooltip from '../components/Tooltip';

export default {
    name: 'Favorites',

    components: {
        NavBar,
        SideBar,
        SplashScreen,
        Tooltip,
        LaneDrilldown
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    methods: {
        formatScore: format.formatScore,
        formatPercent: format.formatPercent,
        formatDollars: format.formatDollars,
        formatDecimal: format.formatDecimal,
        formatDate: format.formatDate,
        formatHours: format.formatHours,
        formatHoursToBusinessDays: format.formatHoursToBusinessDays,
        formatAggregationWeek: format.formatAggregationWeek,
        formatOTD: format.formatOTD,
        formatNumber: format.formatNumber,
        getColor: utils.getColor,
        getFavoriteLanesParam(item) {
            const params = {};

            if (this.isBrokerUser) {
                params.id = item.shipperId;
            } else if (!this.isBrokerUser) {
                params.id = item.brokerId;
            } else {
                this.$forceUpdate();
                return params;
            }

            params.laneId = item.laneId;

            return params;
        },

        isFavoriteLane(item) {
            const params = this.getFavoriteLanesParam(item);

            if (
                params != null &&
                params != undefined &&
                Object.keys(params).length > 0
            ) {
                if (Object.keys(this.favoriteLanes).includes(params.id)) {
                    if (this.favoriteLanes[params.id].includes(params.laneId)) {
                        return true;
                    }
                }
            }

            return false;
        },

        updateFavoriteLanes(item) {
            const params = this.getFavoriteLanesParam(item);

            if (
                params != null &&
                params != undefined &&
                Object.keys(params).length > 0
            ) {
                if (this.isFavoriteLane(item)) {
                    // if laneId in favorites then delete
                    this.favoriteLanes[params.id].splice(
                        this.favoriteLanes[params.id].indexOf(params.laneId),
                        1
                    );
                } else {
                    // if laneId not in favorites then add
                    if (this.favoriteLanes[params.id] == undefined) {
                        this.favoriteLanes[params.id] = [];
                    }
                    this.favoriteLanes[params.id].push(params.laneId);
                }

                // remove unused id
                if (this.favoriteLanes[params.id].length == 0) {
                    delete this.favoriteLanes[params.id];
                }

                stateAPI.setStateProperty(
                    this,
                    'favoriteLanes',
                    this.favoriteLanes
                );
                // get configuration in order to update it
                fetchAccountDetails
                    .getAccountDetails({ email: this.email })
                    .then((result) => {
                        var data = null;
                        var tempConfig = { favoriteLanes: this.favoriteLanes };

                        if (result != undefined && result.status == 200) {
                            data = JSON.parse(result.data.records[0].config);
                        }

                        if (data != null) {
                            data.favoriteLanes = this.favoriteLanes;
                            tempConfig = _.cloneDeep(data);
                        }

                        // Update configuration
                        const query_params = {
                            email: this.email,
                            config: 1,
                            new_config: JSON.stringify(tempConfig)
                        };

                        fetchAccountDetails.updateAccountDetails(query_params);
                    });

                this.items = this.items.filter(
                    (item) =>
                        !(
                            `${
                                this.isBrokerUser
                                    ? item.shipperId
                                    : item.brokerId
                            }` == params.id && item.laneId == params.laneId
                        )
                );
            }
        },

        gotoItemLaneDrilldown(item) {
            stateAPI.setStateProperty(this, 'lane', item.laneId);
            var filterKeyId = null;

            if (this.isBrokerUser) {
                stateAPI.setStateProperty(this, 'shipper', item.shipperId);
                filterKeyId = item.shipperId;
            } else {
                stateAPI.setStateProperty(this, 'broker', item.brokerId);
                filterKeyId = item.brokerId;
            }

            const origin = item.origin;
            const destination = item.destination;
            // replace spaces in origin/destination with underscore for display in url
            const url_origin = origin.replace(/ /g, '_');
            const url_dest = destination.replace(/ /g, '_');

            this.isLoading = true;
            this.drilldownObject = {
                origin: origin,
                destination: destination,
                equipmenttype: item.equipmenttype,
                sb_id: filterKeyId
            };
            this.laneDrilldown = true;
        },

        periodTabChange() {
            stateAPI.setStateProperty(
                this,
                'storeKeyMetricsToggle',
                this.keyMetricsTabIndex
            );
            var tp = this.tabValues[this.keyMetricsTabIndex];
            if (tp == undefined || tp == null) {
                const today = new Date(new Date().setHours(5, 0, 0));
                const start = new Date(
                    new Date(
                        stateAPI.getStateProperty(this, 'startDate')
                    ).setHours(1, 0, 0)
                );
                tp = (
                    (today.getTime() - start.getTime()) /
                        (24 * 60 * 60 * 1000) +
                    1
                ).toFixed(0);
                this.endDate = today;
                this.startDate = start;
            }

            this.startDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() -
                        (parseInt(tp) - 1) * 1000 * 60 * 60 * 24
                ).setHours(5, 0, 0)
            );

            this.getLanes();
        },

        getLanes() {
            this.isLoading = true;
            var query_params = {
                userId: stateAPI.getStateProperty(this, 'user_id'),
                start_date: new Date(
                    new Date(this.startDate) -
                        new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substring(0, 10),
                end_date: new Date(
                    new Date(this.endDate) -
                        new Date().getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .substring(0, 10),
                user_type: this.role,
                favorites: this.favoriteLanes,
                include_accessorials: 1,
                is_broker_user: this.isBrokerUser,
                shipperId: stateAPI.getStateProperty(this, 'user_id')
            };
            fetchShipments.fetchShipmentData(query_params).then((result) => {
                if (result != undefined && result != null) {
                    this.items = result;
                } else {
                    this.items = [];
                }
                this.isLoading = false;
                utils.formatDates(this.startDate, this.endDate, null, this);
            });
        },

        stardardSort(a, b, isDesc) {
            if (!isDesc[0]) {
                return a < b ? -1 : 1;
            }

            return b < a ? -1 : 1;
        },

        favoriteTableSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index == 'margin_per_load') {
                    if (this.marginPerLoadUnit == 'percent') {
                        return this.stardardSort(
                            a.avg_margin,
                            b.avg_margin,
                            isDesc
                        );
                    } else if (this.marginPerLoadUnit == 'dollar') {
                        return this.stardardSort(
                            a.avg_margin_dollars,
                            b.avg_margin_dollars,
                            isDesc
                        );
                    }
                }

                return this.stardardSort(a[index], b[index], isDesc);
            });

            return items;
        }
    },

    beforeMount() {
        // Send pageView analytics event
        user_analytics.sendEvent(
            this.email,
            this.company,
            this.role,
            'pageView',
            {
                pageSource: 'Favorites'
            }
        );

        stateAPI.setStateProperty(this, 'crumbs', this.crumbs);

        if (this.keyMetricsTabIndex != null) {
            const timePeriod = this.tabValues[this.keyMetricsTabIndex];
            this.startDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() -
                        (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
                ).setHours(5, 0, 0)
            );
        }

        this.headers = this.isBrokerUser
            ? this.headers_brokers
            : this.headers_shippers;

        this.getLanes();
    },

    data: function () {
        return {
            isLoading: true,
            headers_brokers: [
                {
                    text: '',
                    align: 'end',
                    value: 'favoriteIcon',
                    sortable: false,
                    class: 'widthWrapStar'
                },
                {
                    text: 'Shipper Name',
                    align: 'center',
                    value: 'name',
                    tooltip: 'Name of shipper.',
                    class: 'widthWrap'
                },
                {
                    text: 'Origin',
                    align: 'center',
                    value: 'origin',
                    tooltip: 'Shipment Origin.',
                    class: 'widthWrap'
                },
                {
                    text: 'Destination',
                    align: 'center',
                    value: 'destination',
                    tooltip: 'Shipment Destination.',
                    class: 'widthWrap'
                },
                {
                    text: 'Score',
                    align: 'end',
                    value: 'score',
                    tooltip:
                        'Truce Score, based various performance metrics, over the period of selected. Metrics include, but are not limited to, Execution, Cost and Service performance.',
                    class: 'pr-5 widthWrapScore'
                },
                {
                    text: 'Equipment Type',
                    align: 'end',
                    value: 'equipmenttype',
                    tooltip: 'Lane equipment type.',
                    class: 'widthWrap'
                },
                {
                    text: 'Avg. Spend ($)',
                    align: 'end',
                    value: 'avg_revenue',
                    tooltip:
                        'Average Spend, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Avg. Truck Cost ($)',
                    align: 'end',
                    value: 'avg_cogs',
                    tooltip:
                        'Average Truck Cost per shipment, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Margin Per Load',
                    align: 'end',
                    value: 'margin_per_load',
                    tooltip:
                        'Average margin per shipment over the time period selected.',
                    class: 'widthWrap'
                },
                {
                    text: 'Total Margin ($)',
                    align: 'end',
                    value: 'total_margin',
                    tooltip:
                        'Total margin per lane, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Volume',
                    align: 'center',
                    value: 'volume',
                    tooltip:
                        'Total shipment Volume, over the time period selected.',
                    class: 'widthWrapVolume'
                },
                {
                    text: '',
                    align: 'start',
                    value: 'gotoDrilldownIcon',
                    sortable: false,
                    class: 'goToWidthWrap'
                }
            ],
            headers_shippers: [
                {
                    text: '',
                    align: 'end',
                    value: 'favoriteIcon',
                    sortable: false,
                    class: 'widthWrapStar'
                },
                {
                    text: 'Broker Name',
                    align: 'center',
                    value: 'name',
                    tooltip: 'Name of broker.',
                    class: 'widthWrap'
                },
                {
                    text: 'Origin',
                    align: 'center',
                    value: 'origin',
                    tooltip: 'Shipment Origin.',
                    class: 'widthWrap'
                },
                {
                    text: 'Destination',
                    align: 'center',
                    value: 'destination',
                    tooltip: 'Shipment Destination.',
                    class: 'widthWrap'
                },
                {
                    text: 'Score',
                    align: 'end',
                    value: 'score',
                    tooltip:
                        'Truce Score, based various performance metrics, over the period of selected. Metrics include, but are not limited to, Execution, Cost and Service performance.',
                    class: 'pr-5 widthWrapScore'
                },
                {
                    text: 'Equipment Type',
                    align: 'end',
                    value: 'equipmenttype',
                    tooltip: 'Lane equipment type.',
                    class: 'widthWrap'
                },
                {
                    text: 'Avg. Spend ($)',
                    align: 'end',
                    value: 'avg_revenue',
                    tooltip:
                        'Average Spend, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Avg. Truck Cost ($)',
                    align: 'end',
                    value: 'avg_cogs',
                    tooltip:
                        'Average Truck Cost per shipment, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Margin Per Load',
                    align: 'end',
                    value: 'margin_per_load',
                    tooltip:
                        'Average margin per shipment over the time period selected.',
                    class: 'widthWrap'
                },
                {
                    text: 'Total Margin ($)',
                    align: 'end',
                    value: 'total_margin',
                    tooltip:
                        'Total margin per lane, in dollars, over the time period selected.',
                    class: 'widthWrapSpend'
                },
                {
                    text: 'Volume',
                    align: 'center',
                    value: 'volume',
                    tooltip:
                        'Total shipment Volume, over the time period selected.',
                    class: 'widthWrapVolume'
                },
                {
                    text: '',
                    align: 'start',
                    value: 'gotoDrilldownIcon',
                    sortable: false,
                    class: 'goToWidthWrap'
                }
            ],
            headers: [],
            items: [],
            favoriteLanes: stateAPI.getStateProperty(this, 'favoriteLanes'),
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker',
            email: stateAPI.getStateProperty(this, 'email'),
            startDate: stateAPI.getStateProperty(this, 'startDate'),
            endDate: new Date(new Date().setHours(5, 0, 0)),
            crumbs: [
                {
                    text: 'Favorites',
                    disabled: true,
                    href: new URL(window.location.href).pathname
                }
            ],

            // Lane drilldown props
            laneDrilldown: false,
            drilldownObject: null,

            keyMetricsTabIndex: stateAPI.getStateProperty(
                this,
                'storeKeyMetricsToggle'
            ),
            tabValues: [14, 30, 60],
            queryInProgress: false,
            role: stateAPI.getStateProperty(this, 'role'),
            company: stateAPI.getStateProperty(this, 'company'),
            periodDates: '',
            isPeriodOneDay: false,
            marginPerLoadUnit: 'percent'
        };
    }
};
</script>
<style>
.favoritesDataTable {
    background-color: var(--v-dataTableBackground-base) !important;
    color: var(--v-dataTableText-base) !important;
}

.favoritesDataTable .widthWrap {
    max-width: 100px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.favoritesDataTable .widthWrapSpend {
    max-width: 87px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.favoritesDataTable .widthWrapVolume {
    max-width: 75px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.favoritesDataTable .widthWrapStar {
    max-width: 20px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.favoritesDataTable .widthWrapScore {
    max-width: 20px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}

.favoritesDataTable .goToWidthWrap {
    max-width: 80px;
    overflow-wrap: break-word !important;
    white-space: normal !important;
    padding-right: 0px;
}
</style>
